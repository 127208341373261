import React, { useEffect, useState } from "react";
import { fetchProducts } from "../action/FutbolistaActions";
import "../css/catalogos/css/formaciones/Cancha.css";
import "../css/catalogos/css/formaciones/3.css";
import "../css/catalogos/css/formaciones/6.css";
import "../css/catalogos/css/formaciones/9.css";
import "../css/catalogos/css/formaciones/11.css";
import "../css/catalogos/css/formaciones/Suplentes.css";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import {Animated} from "react-animated-css";
// import Zoom from "react-reveal/Zoom";

function Formacion1({ciudad}) {
  const config_server = JSON.parse(localStorage.getItem("configServer"));
  const dispatch = useDispatch();
  const calificador = useSelector((state) => state.products);
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;


  const [load, setLoad] = useState(true);
  const [ciudadData, setCiudadData] = useState('ASUNCION');
  if (ciudadData != ciudad) {
    setCiudadData(ciudad);
    setLoad(true);
  }

  useEffect(() => {
    if (load) {
      const loadProducts = () => dispatch(fetchProducts(ciudad));
      loadProducts();
      setLoad(false);
    }
  }, [calificador, dispatch, load]);

// console.log("userinfo", load);
  return (
    <React.Fragment>

      {load ? (
        <Slider />
      ) : (
        <div>
          {/* <div id="imagen_cancha"></div> */}

          <div
            id="cancha"
            // className={calificador.items.calificador.clase_formacion}
          >
            {calificador?.items?.map((formacion) => {
              let opacarImg = "";
              let opacarTitle = "";
              let opacarCursor = "";

              let habilitar = (event) => true;
              // const calificados= calificador.items.calificados;

              // for (const key in calificados) {

              //     const id_auto = calificados[key]["id_producto"];
              //     if(id_auto === formacion.id_auto){
              //       opacarImg ="opacarImg";
              //       opacarTitle ="opacarTitle";
              //       habilitar= (event) => event.preventDefault(true);
              //       opacarCursor="opacarCursor";

              //       console.log("entro")
              //     }

              // return (<></>);
              // }
              // console.log("calificados",formacion)
              let zindex = "";
              // if(formacion.id_auto !== "0"){
              //   zindex = 10;
              // }
              return (
                <div
                  key={formacion.id_producto}
                  className={`jugador titular  ${formacion.id_producto} ${opacarImg}`}
                  style={{ zIndex: `${zindex}` }}
                  id={formacion.id_producto}
                >
                  <Animated
                    animationIn="bounceIn"
                    animationInDelay={1500}
                    animationOut="bounce"
                    animationInDuration={2000}
                    animationOutDuration={2000}
                    isVisible={true}
                  >
                    <Link
                      className={`link-name  ${opacarCursor}`}
                      onClick={habilitar}
                      to={`/catalogo/producto/${formacion.id_producto}/${formacion.imagen_producto}`}
                    >
                      {formacion.imagen_producto === "" ? (
                        <div>
                          <img
                            className="camiseta"
                            src="/fans/boy1-sm.png"
                            alt="Emprendimientos virtuales sa"
                            style={{ display: "none" }}
                          />

                          <p
                            className={`nombreju ${opacarTitle}`}
                            style={{ display: "none" }}
                          >
                            {formacion.nombre_producto}
                          </p>
                        </div>
                      ) : (
                        <div>
                          <img
                            alt="Emprendimientos virtuales sa"
                            className="camiseta"
                            src={`${config_server.linkImageProducto}${formacion.imagen_producto}`}
                          />
                          <p className={`nombreju ${opacarTitle}`}>
                            {formacion.nombre_producto}
                          </p>
                        </div>
                      )}
                    </Link>
                  </Animated>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default Formacion1;
