import React from "react";
// import { useDispatch } from "react-redux";



function ContactoHome2() {


 
  // const dispatch = useDispatch();


  
  return (
    <React.Fragment>

      <section>

     
        <div className="container-azul223">
        <div className="cab-verde3" >
          <h1 className="h1-1 centrar">Contactanos</h1>
          </div>
          <div className="container-azul3">

              <div className="redes-cont3">
          <img className="redes-contacto3" src="/fans/ig.png" alt="instagram"/>
          <img className="redes-contacto3" src="/fans/tw.png" alt="twitter"/>
          <img className="redes-contacto3" src="/fans/fb.png" alt="facebook" />
          </div>
          </div>
          </div>

       
      </section>
    </React.Fragment>
  );
}
export default ContactoHome2;
