// import React , { useState } from "react";
// import "../App.css";
// import { Link } from "react-router-dom";
// import Navbar from 'react-bootstrap/Navbar';
// import Nav from 'react-bootstrap/Nav';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import Container from 'react-bootstrap/Container';
// import BotonUser2 from "../userBotones/BotonUser2";
// import BotonUser from "../userBotones/BotonUser";
// import logohome from "../assets/imagenes/fans/logo-menu.png";



// class Navigation extends React.Component {
//     const [isMenuOpen, setMenuOpen] = useState(false);

//   render() {
//     const openMenu = () => {
//       document.querySelector(".sidebar").classList.add("open");
//     };

// const handleMenuToggle = () => {
//     setMenuOpen(!isMenuOpen);
//   };

//   const closeMenu = () => {
//     setMenuOpen(false);
//   };

//     return (
//       <Navbar collapseOnSelect expand="lg" bg="#13141f" variant="dark">
//       <Container>
//         <Navbar.Brand href="#home">
//           <Link to="/">
//             <img src={logohome} className="icon-logo" alt="SÓLO FÁNATICOS" />
//           </Link>
//         </Navbar.Brand>
//         <div className="menu">
//         <BotonUser2/>
//         <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//         </div>
//         <Navbar.Collapse id="responsive-navbar-nav" in={isMenuOpen} >
//           <Nav className="me-auto" >
//           {/* <Nav.Link href="/signin" id="Iniciar sesión" className="lista-sidebar-n"><BotonUser/></Nav.Link> */}
//           <Link to="/" onClick={closeMenu} id="Inicio" className="lista-sidebar-n">Inicio</Link>
//            <Link to="/" onClick={closeMenu} id="asuncion" className="lista-sidebar-n">Asunción</Link>
//            <Link to="/" onClick={closeMenu} id="lambare" className="lista-sidebar-n">Lambare</Link>
//            <Link to="/" onClick={closeMenu} id="luque" className="lista-sidebar-n">Luque</Link>
//            <Link to="/" onClick={closeMenu} id="sanlorenzo" className="lista-sidebar-n">San Lorenzo</Link>
//            <Link to="/" onClick={closeMenu} id="encarnacion" className="lista-sidebar-n">Encarnacion</Link>
//            <Link to="/" onClick={closeMenu} id="cde" className="lista-sidebar-n">C.D.E</Link>
//            {/* <Link to="/" onClick={closeMenu} id="nemby" className="lista-sidebar-n">Ñemby</Link> */}
//            <Link to="/" onClick={closeMenu} id="fdm" className="lista-sidebar-n">Fernado de la Mora</Link>
//             {/* <Link href="/como-participar" id="juegos" className="lista-sidebar-n">¿Cómo participar?</Link>
//             <Link href="/#califica" className="lista-sidebar-n">Calificá</Link>
//             <NavDropdown title="Calificaciones" id="collasible-nav-dropdown" className="lista-sidebar-n ">
//               <NavDropdown.Item href={`/informes/hombres`} className="lista-sidebar-nsub">
//                 Hombres
//               </NavDropdown.Item>
//               <NavDropdown.Item href={`/informes/mujeres`} className="lista-sidebar-nsub">
//                 Mujeres
//               </NavDropdown.Item>
             
//               <NavDropdown.Divider />
//               <NavDropdown.Item href={`/informes/general`}className="lista-sidebar-nsub">
//                 General
//               </NavDropdown.Item> */}
//             {/* </NavDropdown> */}
//           </Nav>
//           <Nav>
//           {/* <NavDropdown title="Documentos" id="collasible-nav-dropdown1" className="lista-sidebar-n ">
//               <NavDropdown.Item href="/informes-mujeres" className="lista-sidebar-nsub">
//               Términos y Condiciones
//               </NavDropdown.Item>
//               <NavDropdown.Item href="/informes-socios"className="lista-sidebar-nsub" >
//               Política de Privacidad
//               </NavDropdown.Item >
//             </NavDropdown>
          
//             <Link href="/#contacto" className="lista-sidebar-n">Contacto</Link> */}
            
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//       // <div className="navigation">
//       //   <nav className="nav1" >
//       //     <div className="contenedor-nav" >
            
              
              
            
//       //       {/* <Tada> */}
//       //         <Link to="/">
//       //           <img
//       //             src="../../../fans/logo-menu.png"
//       //             className="icon-logo"
//       //             alt="SÓLO FÁNATICOS"
//       //           />
//       //         </Link>
//       //         {/* </Tada> */}
//       //         <div className="menu33">
//       //         <BotonUser2/>
           
//       //       <button className="icono-button" onClick={openMenu}>
//       //           <img
//       //             src="../../../fans/icon-menu.png"
//       //             className="icono"
//       //             alt="Sólo Fanáticos"
//       //           />
//       //         </button>
//       //         </div>
//       //     </div>
//       //   </nav>

//       //   <div className="sidebar">
          
//       //     <Sidebar />
//       //   </div>
//       //   <nav className="nav2">
//       //     <div className="contenedor-nav2" >


//       //       <Sidebar1/>

 
//       //     </div>
//       //   </nav>
//       // </div>
    
//     );
//   }
// }
// export default Navigation;



import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import BotonUser2 from "../userBotones/BotonUser2";
import logohome from "../assets/imagenes/fans/logo-menu.png";

const Navigation = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <Navbar collapseOnSelect expand="lg" bg="#13141f" variant="dark">
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img src={logohome} className="icon-logo" alt="SÓLO FÁNATICOS" />
          </Link>
        </Navbar.Brand>
        <div className="menu">
          {/* <BotonUser2/> */}
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={handleMenuToggle}
          />
        </div>
        <Navbar.Collapse id="responsive-navbar-nav" in={isMenuOpen} >
          <Nav className="me-auto">
         
           <Link to="/" onClick={closeMenu} id="Inicio" className="lista-sidebar-n">Inicio</Link>
           <Link to="/ciudad/ASUNCION" onClick={closeMenu} id="asuncion" className="lista-sidebar-n">Asunción</Link>
           <Link to="/ciudad/LAMBARE" onClick={closeMenu} id="lambare" className="lista-sidebar-n">Lambare</Link>
           <Link to="/ciudad/LUQUE" onClick={closeMenu} id="luque" className="lista-sidebar-n">Luque</Link>
           <Link to="/ciudad/SAN LORENZO" onClick={closeMenu} id="sanlorenzo" className="lista-sidebar-n">San Lorenzo</Link>
           <Link to="/ciudad/ENCARNACION" onClick={closeMenu} id="encarnacion" className="lista-sidebar-n">Encarnacion</Link>
           <Link to="/ciudad/C.D.E" onClick={closeMenu} id="cde" className="lista-sidebar-n">C.D.E</Link>
           {/* <Link to="/ciudad/" onClick={closeMenu} id="nemby" className="lista-sidebar-n">Ñemby</Link> */}
           <Link to="/ciudad/FERNANDO DE LA MORA" onClick={closeMenu} id="fdm" className="lista-sidebar-n">Fernado de la Mora</Link>
          </Nav>
          <Nav>
            {/* Más enlaces Link */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
