import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import Layouts from "./layout/Layouts";
import PrivateRoute from "./routes/PrivateRoute";
import server from "./config/server";
import Spinner from "./componentes/Spinner";
import Home2 from "./screen/Home2"

const CalificacionesClientesTodos = lazy(() => import('./screen/CalificacionesClientesTodos'))
const VideoCentral = lazy(() => import('./screen/VideoCentral'))
const JugadorId = lazy(() => import('./screen/JugadorId'))
const Error = lazy(() => import('./screen/Error'))
const Notfound = lazy(() => import('./screen/Notfound'))
const LoginHome = lazy(() => import('./screen/LoginHome'))
const RegisterHome = lazy(() => import('./screen/RegisterHome'))
const RecuperarHome = lazy(() => import('./screen/RecuperarHome'))
const DetalleHome = lazy(() => import('./screen/DetalleHome'))
const MicuentaHome = lazy(() => import('./userScreens/MicuentaHome'))
const EditarCuentaHome = lazy(() => import('./userScreens/EditarCuentaHome'))
const EditarContraseñaHome = lazy(() => import('./userScreens/EditarContraseñaHome'))
const PedidosHome = lazy(() => import('./userScreens/PedidosHome'))
const Informes = lazy(() => import('./informes/Informes'))







class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { status: false };
  }

  componentDidMount() {
    const API = server.baseURL + "catalogos/paginas/configuraciones";
    fetch(API, {
      method: "GET",
      
    })
    
      .then((response) => response.json())
      
      .then((data) => {
        //console.log("config");

        localStorage.setItem("configServer", JSON.stringify(data.data));

        this.setState({
          status: true,
        });
      })

      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    let loading;
    if (this.state.status === false ) {
      loading = (
        <div style={{ height: "75vh", margin: 0, padding: 0 }}>
          <span
           
          >
            <Spinner />
          </span>
        </div>
      );
    } else {
      loading = (
        <Switch>
          <Route exact path="/" component={Home2}></Route>
          <Route exact path="/ciudad/:ciudad" component={Home2}></Route>
          <Route exact path="/ciudad/:ciudad/:tipo" component={DetalleHome}></Route>
          <Route path="/como-participar" component={VideoCentral} />
          <Route path="/signin" component={LoginHome}></Route>
          <Route path="/register" component={RegisterHome}></Route>
          <Route path="/codigo" component={RecuperarHome}></Route>
          <Route path="/error" component={Error}></Route>
          <Route exact path="/catalogo/producto/:id/:imagen" component={JugadorId}></Route>
           
           
          <Route
            path="/calificaciones-encuentros"
            component={CalificacionesClientesTodos}
          ></Route>
          <Route path="/informes/:id" component={Informes}></Route>
          <PrivateRoute
            path="/mi-cuenta"
            component={MicuentaHome}
          ></PrivateRoute>
         
          <PrivateRoute
            path="/editar-cuenta"
            component={EditarCuentaHome}
          ></PrivateRoute>
          <PrivateRoute
            path="/editar-contraseña"
            component={EditarContraseñaHome}
          ></PrivateRoute>
          <PrivateRoute path="/pedidos" component={PedidosHome}></PrivateRoute>
          <PrivateRoute
            path="/detalle-autos"
            component={DetalleHome}
          ></PrivateRoute>
          <Route exact path="*" component={Notfound}></Route>
        </Switch>
      );
    }
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Layouts>
            <Suspense fallback={<span><Spinner/></span>}>{loading}</Suspense>
          </Layouts>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;